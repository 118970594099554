import CoveoComponent from '../../../ui/coveoComponent/CoveoComponent.vue';
import { buildResultList, buildSearchBox } from '@coveo/headless';
export default {
  extends: CoveoComponent,
  data() {
    return {
      controller: null,
      resultController: null,
      count: 0,
      mySelector: 'website-updates',
      type: 'input',
      showBanner: false,
      showUpdatesBanner: window.sessionStorage.getItem('showUpdatesBanner') == 'true',
      isLoading: false
    };
  },
  methods: {
    getI18nMessage: function (key) {
      return window.Granite.I18n.get(key);
    },
    closeBanner() {
      this.showBanner = false;
      this.showUpdatesBanner = false;
      window.sessionStorage.setItem('showUpdatesBanner', false);
    },
    hasRecentUpdates(date) {
      if (!date || Number.isNaN(date)) return false;
      let startDate = new Date(date);
      let todayDate = Date.now();
      let days = Math.round((todayDate - startDate) / (1000 * 60 * 60 * 24));
      return days < 6;
    },
    handleTrackLink(e) {
      if (e.target.parentNode.nodeName == 'A') {
        this.$store.dispatch('trackLink', {
          name: 'Explore Now',
          component: 'website-updates-banner'
        });
      }
    }
  },
  computed: {
    searchEngine() {
      return this.$store.state.search.engines[this.mySelector]?.engine;
    },
    state() {
      this.count;
      return this.resultController?.state;
    },
    hasResults() {
      return this.state && this.state.results.length > 0;
    },
    results() {
      return this.state?.results;
    }
  },
  mounted() {},
  watch: {
    isLoggedIn: function () {
      return this.$store.state.user.isAuthenticated;
    },
    results(newVal, oldVal) {
      if (newVal && newVal.length > 0) {
        this.showBanner = this.hasRecentUpdates(newVal[0].raw.dow_startdate);
        window.sessionStorage.setItem('showUpdatesBanner', this.showBanner);
      }
    },
    searchEngine(newVal, oldVal) {
      if (newVal && window.sessionStorage.getItem('fetchedWebsiteUpdate') != 'true') {
        this.resultController = buildResultList(this.searchEngine, {
          options: {
            fieldsToInclude: ['dow_startdate', 'dow_enddate']
          }
        });
        this.resultController.subscribe(() => this.count++);
        this.controller = buildSearchBox(this.searchEngine);
        this.controller.subscribe(() => this.count);
        this.controller.submit();
      }
    },
    state(newVal, oldVal) {
      if (newVal && newVal.isLoading) {
        this.isLoading = true;
      }
      if (this.isLoading && newVal && !newVal.isLoading) {
        window.sessionStorage.setItem('fetchedWebsiteUpdate', true);
      }
    }
  }
};