import ContractDetailsDTOService from './contract-details-dto-service'
import ContractDetailsDefaultValueHelper from './filter-helpers/contract-details-default-value-helper'

export const ContractDetailsModuleHelper = {
    namespaced: true,
    state: {
        items: [],
        cartItems: [],
        item: {},
        itemsLoaded: false,
        filters: {},
        filterDefaultValueHelper: ContractDetailsDefaultValueHelper,
        sortOptions: {
            attribute: 'contractActionText',
            sortType: 'alphabetic',
            sortDirection: 'asc'
        },
        itemsUrl: 'get_contract_details',
        itemUrl: '',
        requestType: 'POST',
        resourceName: 'contractDetails',
        resourceDto: ContractDetailsDTOService.getContractDetailsDTO,
        clientSideFilters: [],
        modeView: 'list',
        hasRequestError: false,
    }
}
