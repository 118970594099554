import CoreComponent from '../../../core/CoreComponent.vue';
import GlobeIcon from '../../../commerce/blocks/svg-elements/language-selector-icon/language-selector-icon.vue';
import ArrowSmall from '../../../commerce/blocks/svg-elements/arrow-sm/arrow-sm.vue';
import Arrow from '../../../commerce/blocks/svg-elements/arrow/arrow.vue';
import { getCookiebyName } from '@/utils/cookies.js';
import { purifyLink } from '@/utils/url.js';
import { useDebounceFn } from '@vueuse/core';
import HumanMachineLanguage from '../human-machine-language/human-machine-language.vue';
import { inject } from 'vue';
import LoadingDots from '@/dow-platform/components/commerce/blocks/output-elements/loading-dots/LoadingDots.vue';
const debouncedFn = useDebounceFn(() => window.innerWidth < 1025, 1000);
export default {
  extends: CoreComponent,
  components: {
    GlobeIcon,
    ArrowSmall,
    Arrow,
    HumanMachineLanguage,
    LoadingDots
  },
  data() {
    return {
      mobileAndTabletCheck: false,
      isChangingLang: false
    };
  },
  created() {
    window.addEventListener('resize', this.setIsMobileOrTablet);
  },
  setup() {
    const languageDropDownModal = inject('languageDropDownModal');
    return {
      languageDropDownModal
    };
  },
  mounted() {
    this.closeLanguageModalByEsc();
    this.handleKeyboardNavigation();
    this.setIsMobileOrTablet();
  },
  unmounted() {
    window.removeEventListener('resize', this.setIsMobileOrTablet);
  },
  methods: {
    getLabel(key) {
      return window.Granite.I18n.get(key);
    },
    toggleLanguageModal() {
      this.languageDropDownModal.toggleLangugageDropdown();
    },
    closeLanguageModalByEsc() {
      document.addEventListener("keydown", event => {
        if (event.key === "Escape") {
          if (this.showModal) {
            this.toggleLanguageModal();
          }
        }
      });
    },
    getAnchorFromURL(url) {
      var Anchors = url.split('#');
      if (Anchors.length > 1) {
        return "#" + Anchors[1];
      } else {
        return "";
      }
    },
    async switchLanguage(languageCode, path) {
      this.isChangingLang = true;
      await this.$store.dispatch('browser/setLanguageCode', languageCode);
      if (localStorage.getItem('dowComCoveoToken')) {
        localStorage.removeItem('dowComCoveoToken');
      }
      if (window.location.href.includes('/dccstorefront/')) {
        window.location.reload();
      } else {
        let newPath = path + this.getAnchorFromURL(window.location.href);
        if (!window.location.href.includes(newPath)) {
          window.location.href = newPath;
        } else {
          window.location.reload();
        }
      }
    },
    setIsMobileOrTablet() {
      debouncedFn().then(value => {
        this.mobileAndTabletCheck = value;
      });
    },
    handleKeyboardNavigation() {
      const links = document.querySelectorAll('.cmp--header_language-modal .cmp--header_language-modal_option');
      if (links.length) {
        links.forEach((link, index) => {
          link.addEventListener('keydown', function (event) {
            const key = event.key;
            const prev = links[index - 1];
            const next = links[index + 1];
            if (key === 'ArrowUp') {
              event.preventDefault();
              if (prev) {
                prev.focus();
              } else {
                links[links.length - 1].focus();
              }
            }
            if (key === 'ArrowDown') {
              event.preventDefault();
              if (next) {
                next.focus();
              } else {
                links[0].focus();
              }
            }
          });
        });
      }
    }
  },
  computed: {
    currentLang() {
      return this.cmpEmbeddableData.currentLanguage;
    },
    linkURL() {
      return this.cmpEmbeddableData.linkURL;
    },
    showModal() {
      return this.languageDropDownModal.isLanguageDropdownOpen.value;
    },
    humanMachineFlag() {
      let machineLanguageObject = this.cmpEmbeddableData.machineLanguages;
      if (machineLanguageObject != null && Object.keys(machineLanguageObject).length === 0) {
        return false;
      } else {
        return true;
      }
    },
    languages() {
      var entries = {};
      if (this.cmpEmbeddableData.languages) {
        Object.keys(this.cmpEmbeddableData.languages).forEach(orderKey => {
          Object.keys(this.cmpEmbeddableData.languages[orderKey]).forEach(key => {
            entries[key] = JSON.parse(this.cmpEmbeddableData.languages[orderKey][key]);
          });
        });
      }
      return entries;
    },
    machineLanguages() {
      var entries = {};
      if (this.cmpEmbeddableData.machineLanguages) {
        Object.keys(this.cmpEmbeddableData.machineLanguages).forEach(orderKey => {
          Object.keys(this.cmpEmbeddableData.machineLanguages[orderKey]).forEach(key => {
            entries[key] = JSON.parse(this.cmpEmbeddableData.machineLanguages[orderKey][key]);
          });
        });
      }
      return entries;
    },
    languageKeys() {
      return this.languages != null ? Object.keys(this.languages) : [];
    },
    tagDescription() {
      return this.cmpEmbeddableData.tagDescription;
    },
    teleportID() {
      if (this.mobileAndTabletCheck) {
        return '#mega-menu-language-selector';
      } else {
        return this.cmpEl;
      }
    }
  }
};