var PAGE_VIEW_DLM = 'Page Viewed'
var LANGUAGE_SELECTED_DLM = 'Language Selected'

import {
    getRandomComponentName,
    CMP_SHOW_VIRTUAL
} from '@platform/dow-platform/components/core/ACDLHandler'
import LanguageHelper from '@platform/helpers/language-helper'
import { CoveoAnalyticsClient } from 'coveo.analytics/modules'

export default {
    namespaced: true,
    state: {
        pageInfo: {
            pageName: 'not set',
            pageType: 'not set',
            pageURL: window.location.href,
            siteRegion: 'not set',
            siteLanguage: 'not set',
            site: window.location.host,
            lastUpdated: 'not set'
        },
        attributes: {},
        category: {
            primaryCategory: 'not set',
            subCategory1: 'not set',
            subCategory2: 'not set',
            subCategory3: 'not set',
            subCategory4: 'not set'
        },
        loadCurated: true
    },
    getters: {
        locale: getLocale,
        name: getPageName,
        site: getSite,
    },
    mutations: {
        pageInfo: setPageInfo,
        attributes: setPageAttributes,
        category: setCategory,
        locale: setLocale,
        loadCurated: setLoadCurated
    },
    actions: {
        view: pageViewed,
        CCview: pageView,
        selectLocale: { root: true, handler: selectLocale },
        getCoveoSearchToken,
        ['page:load']: {
            root: true,
            handler() {
            }
        },
        ['cmp:show']: {
            root: true,
            handler(context, event) {
                if (event.page) {
                    // check this because cmp:show is also used
                    // by other components
                    const page =
                        event.page[event.eventInfo.path.replace('page.', '')]
                    context.commit('locale', page)
                    context.commit('pageInfo', page)
                }
            }
        },
        [CMP_SHOW_VIRTUAL]: {
            root: true,
            handler(context, event) {
                if (event.page) {
                    const page =
                        event.page[event.eventInfo.path.replace('page.', '')]
                    context.commit('locale', page)
                    context.commit('pageInfo', page)
                }
            }
        },
        load({ rootState, dispatch }) {
            let isOnNewJobFinderPage = rootState.page.pageInfo.pageName === ("jobs");
            let isContentRecommenderEnabled = document.getElementById("jobs-recommendation") !== null;
            let searchHub = (isOnNewJobFinderPage && isContentRecommenderEnabled) ? 'CorporateDowComJobRecommender' : 'DowComMainSearch';

            dispatch('getCoveoSearchToken', searchHub).then((result) => {
                window.adobeDataLayer.push({
                    event: "page:load",
                    user: {
                        webAccountId: rootState.user.dccUserInfo?.data?.ldapGuid,
                        webAccountDomain: rootState.user.dccUserInfo?.data?.isInternalUser ? 'dow' : 'external',
                        deliveryCountry: rootState.user.dccUserInfo?.data?.defaultDeliveryAddress?.shipToAddress?.country?.isocode
                            || rootState.browser.country,
                        companyName: rootState.user.dccUserInfo?.data?.userAddress?.companyName || 'Undefined',
                        coveoVisitorId: result?.clientId
                    },
                    config: {
                        coveoAuthToken: result?.token 
                    }
                })
            })
        }
    }
}

function getPageName(state) {
    return state.pageInfo.pageName
}

function selectLocale(context, pageInfo) {
    var current = context.getters.locale
    context.commit('locale', pageInfo)

    return (
        context.getters.locale !== current &&
        context.dispatch(
            'track',
            {
                event: LANGUAGE_SELECTED_DLM,
                language: {
                    selected: context.getters.locale
                }
            },
            { root: true }
        )
    )
}

function pageViewed({ commit, dispatch, state }, pageViewData) {
    if (!pageViewData.page) return
    commit('pageInfo', pageViewData.page.pageInfo)
    commit('attributes', pageViewData.page.attributes)
    let params = new URLSearchParams(window.location.search.substring(1))
    let category = {
        ...pageViewData.category
    }
    if (state.pageInfo.pageType == 'seek-together' && params.has('filter')) {
        category = {
            primaryCategory: params.get('filter'),
            subCategory1: category.primaryCategory,
            subCategory2: category.subCategory1,
            subCategory3: category.subCategory2,
            subCategory4: category.subCategory3
        }
    }
    commit('category', category)
    if (
        pageViewData.page.pageInfo.pageName !=
        window.settings.MYACCOUNT_PAGENAME
    )
        return dispatch(
            'track',
            {
                event: PAGE_VIEW_DLM,
                page: {
                    pageInfo: state.pageInfo,
                    attributes: state.attributes
                },
                category: state.category
            },
            { root: true }
        ).then(function () {
            dispatch('pageViewCompleted', null, { root: true })
        })
}

function setCategory(state, category) {
    if (!category) return
    state.category = category
}

function setPageAttributes(state, attributes) {
    if (!attributes) return
    if (!'oneTrustEnabled' in attributes)
        attributes.oneTrustEnabled = state.page.attributes.oneTrustEnabled

    const utm = window.location.search.match(/utm_[^&]+/gi)
    if (utm) attributes.utm = utm.join('&')

    state.attributes = attributes
}

function getLocale(state) {
    return state.pageInfo.siteLanguage + '-' + state.pageInfo.siteRegion
}

function getSite(state) {
    return state.pageInfo.site
}

function setLocale(state, pageInfo) {
    if (typeof pageInfo == 'string')
        pageInfo = pageInfo.split('-').reduce(function (pageInfo, part, i) {
            pageInfo[i == 0 ? 'siteLanguage' : 'siteRegion'] = part
            return pageInfo
        }, {})

    if (pageInfo?.siteRegion && pageInfo?.siteLanguage) {
        state.pageInfo.siteRegion = pageInfo.siteRegion
        state.pageInfo.siteLanguage = pageInfo.siteLanguage
    }
}

function setPageInfo(state, pageInfo) {
    state.pageInfo.pageURL = pageInfo.pageURL || window.location.href
    state.pageInfo.site = pageInfo.site || window.location.hostname
    if (pageInfo.pageType) state.pageInfo.pageType = pageInfo.pageType
    if (pageInfo.pageName) state.pageInfo.pageName = pageInfo.pageName
    if (pageInfo.lastUpdated) state.pageInfo.lastUpdated = pageInfo.lastUpdated
    setLocale(state, pageInfo)
    setLoadCurated(state)

}

//CC Page View
export function getRootPage() {
    var temp = adobeDataLayer.getState('page')
    return temp ? temp[Object.keys(temp)[0]] : {}
}

export function pageView({ state }, data) {
    //create pseudo-component
    var compName = getRandomComponentName('page-')
    var retVal = `page.${compName}`

    var rootPage = getRootPage()
    var langCode = LanguageHelper.getLanguageCodeFromCookie().split('-')

    window.adobeDataLayer.push({
        event: CMP_SHOW_VIRTUAL,
        eventInfo: {
            path: retVal
        },
        page: {
            [compName]: {
                '@type': 'analytics/pseudo/page',
                pageName: data.pageName,
                pageType: data.pageType,
                'repo:modifyDate': rootPage['repo:modifyDate'] || 'not set',
                firstPublished: rootPage['repo:publishDate'] || 'not set',
                webAccountId: data.webAccountId || 'not set',
                siteRegion: langCode[1] || 'not set',
                siteLanguage:
                    langCode[0] || rootPage['xdm:language'] || 'not set',
                deliveryCountry: data.deliveryCountry || 'not set',
                businessAlignment: rootPage['businessAlignment'] || 'not set',
                category: data.category,
                subCategory1: data.subCategory1,
                subCategory2: data.subCategory2,
                subCategory3: data.subCategory3,
                previousPageName: data.previousPageName,
                previousPageUrl: data.previousPageURL
            }
        }
    })
}

function getCoveoSearchToken({state, dispatch}, searchHub) {
    return new Promise((resolve) => {
        if(state.pageInfo.site.includes("corporate") && searchHub !== "CorporateDowComJobRecommender") {
            resolve()
        }
        else {
            dispatch('search/fetchSearchToken', {hub: searchHub}, {root:true} ).then((result) => {
                let coveoClientId = ''
                 if(!localStorage.getItem('visitorId')){
                    const ua = new CoveoAnalyticsClient({token: result?.data?.token, endpoint: result?.data?.org + '.analytics.org.coveo.com/rest/ua'})
                    coveoClientId = ua.currentVisitorId
                 }
                 else {
                    coveoClientId = localStorage.getItem('visitorId')
                 }

                let analyticsData = {
                    token: result?.data?.token,
                    clientId: coveoClientId
                }
                resolve(analyticsData) 
            })
            .catch((error) => {
                console.error("Error fetching search token:", error);
                reject(error);
            })
        }
    })
}

function setLoadCurated(state) {
    state.loadCurated = state.pageInfo.pageURL.includes("my-account.html") ? state.pageInfo.pageURL.includes("/website-updates") : true
}
