import FilterDefaultValueHelper from './filter-default-value-helper'

export default class ContractDetailsDefaultValueHelper extends FilterDefaultValueHelper {
    static defaultValues

    static setDefaultValues() {
        this.defaultValues = {
            addresses: super.getDefaultAddress()
        }
    }
}
