import ApiClient from '@/plugins/AxiosPlugin'
import { SortHelper } from '../helpers/sort-helper'
import OrderManagementDTOService from '../dow-platform/components/commerce/myAccount/helper/order-management-dto-service'
import InvoiceManagementDTOService from '../dow-platform/components/commerce/myAccount/helper/invoice-management-dto-service'
import SupportCenterDTOService from '../dow-platform/components/commerce/myAccount/helper/support-center-dto-service'
import OrderFiltersDefaultValueHelper from '../dow-platform/components/commerce/myAccount/helper/filter-helpers/order-filters-default-value-helper'
import DeliveryFiltersDefaultValueHelper from '../dow-platform/components/commerce/myAccount/helper/filter-helpers/delivery-filters-default-value-helper'
import AccountSummaryFiltersDefaultValueHelper from '../dow-platform/components/commerce/myAccount/helper/filter-helpers/account-summary-filters-default-value-helper'
import InvoicesFiltersDefaultValueHelper from '../dow-platform/components/commerce/myAccount/helper/filter-helpers/invoices-filters-default-value-helper'
import CaseFiltersDefaultValueHelper from '../dow-platform/components/commerce/myAccount/helper/filter-helpers/case-filters-default-value-helper'
import { MyPartNumbersModuleHelper } from '../dow-platform/components/commerce/myAccount/helper/my-part-numbers-module-helper'
import { PreviousPurchasesModuleHelper } from '../dow-platform/components/commerce/myAccount/helper/previous-purchases-module-helper'
import { FavoriteProductsModuleHelper } from '../dow-platform/components/commerce/myAccount/helper/favorite-products-module-helper'
import { FavoriteBuyingOptionsModuleHelper } from '../dow-platform/components/commerce/myAccount/helper/favorite-buying-options-module-helper'
import MyPartNumbersFiltersDefaultValueHelper from '../dow-platform/components/commerce/myAccount/helper/filter-helpers/my-part-numbers-filters-default-value-helper'
import FavoriteBuyingOptionsFiltersDefaultValueHelper from '../dow-platform/components/commerce/myAccount/helper/filter-helpers/favorite-buying-options-filters-default-value-helper'
import PreviousPurchasesFiltersDefaultValueHelper from '../dow-platform/components/commerce/myAccount/helper/filter-helpers/previous-purchases-filters-default-value-helper'
import { ContractDetailsModuleHelper } from '../dow-platform/components/commerce/myAccount/helper/contract-details-module-helper'
import i18nHelper from '@/dow-platform/components/commerce/myAccount/helper/i18n-helper'
import ServletHelper from '../dow-platform/components/commerce/myAccount/helper/servlet-helper'

const INTERNAL_USER_SEARCH_OPTIONS = [
    {
        title: i18nHelper.getI18nMessage('supplier.company'),
        value: 'companyName',
        placeholder: i18nHelper.getI18nMessage('account.searchForUser.wildCard'),
        minLength: 3
    },
    {
        title: i18nHelper.getI18nMessage('family.name.Title'),
        value: 'familyName',
        placeholder: i18nHelper.getI18nMessage('account.searchForUser.wildCard'),
        minLength: 3
    },
    {
        title: i18nHelper.getI18nMessage('company.profile.manageUsers.search.shipToNumber'),
        value: 'shipToNo',
        placeholder: i18nHelper.getI18nMessage('account.searchForUser.wildCard'),
        minLength: 1
    },
    {
        title: i18nHelper.getI18nMessage('sold.to.no.Title'),
        value: 'soldToNo',
        placeholder: i18nHelper.getI18nMessage('account.searchForUser.wildCard'),
        minLength: 1
    },
    {
        title: i18nHelper.getI18nMessage('login.username'),
        value: 'username',
        placeholder: i18nHelper.getI18nMessage('account.searchForUser.wildCard'),
        minLength: 3
    }
];

const SOLD_TO_SEARCH_OPTIONS = [
    {
        title: i18nHelper.getI18nMessage('location.name.Title'),
        value: 'customerName',
        placeholder: i18nHelper.getI18nMessage('location.name.Title'),
        minLength: 3
    },
    {
        title: i18nHelper.getI18nMessage('customer.no.Title'),
        value: 'customerNumber',
        placeholder: i18nHelper.getI18nMessage('customer.no.Title'),
        minLength: 1
    }
];

const SOLD_TO_SHIP_TO_SEARCH_OPTIONS = [
    {
        title: i18nHelper.getI18nMessage('city.Title'),
        value: 'town',
        placeholder: i18nHelper.getI18nMessage('account.searchForUser.wildCard'),
        minLength: 3
    },
    {
        title: i18nHelper.getI18nMessage('customer.no.Title'),
        value: 'sapCustomerID',
        placeholder: i18nHelper.getI18nMessage('account.searchForUser.wildCard'),
        minLength: 1
    },
    {
        title: i18nHelper.getI18nMessage('location.name.Title'),
        value: 'name',
        placeholder: i18nHelper.getI18nMessage('account.searchForUser.wildCard'),
        minLength: 3
    },
    {
        title: i18nHelper.getI18nMessage('postal.code.Title'),
        value: 'postalCode',
        placeholder: i18nHelper.getI18nMessage('account.searchForUser.wildCard'),
        minLength: 3
    },
    {
        title: i18nHelper.getI18nMessage('state'),
        value: 'region',
        placeholder: i18nHelper.getI18nMessage('account.searchForUser.wildCard'),
        minLength: 3
    }
];

export const myAccountItems = {
    namespaced: true,
    modules: {
        order: {
            namespaced: true,
            state: {
                items: [],
                item: {},
                itemsLoaded: false,
                filters: {},
                filterDefaultValueHelper: OrderFiltersDefaultValueHelper,
                sortOptions: {
                    attribute: 'orderDate',
                    sortType: 'date',
                    sortDirection: 'dsc'
                },
                itemsUrl: 'get_orders',
                itemUrl: 'get_order',
                requestType: 'POST',
                resourceName: 'orders',
                exportResourceName: 'orderExport',
                resourceDto: OrderManagementDTOService.getOrderDTO,
                resourceDetailsDto:
                    OrderManagementDTOService.getOrderDetailsDTO,
                clientSideFilters: ['orderStatus'],
                itemsResponseDataHandler: handleOrderManagementResponseData,
                modeView: 'list'
            }
        },
        delivery: {
            namespaced: true,
            state: {
                items: [],
                item: {},
                itemsLoaded: false,
                filters: {},
                filterDefaultValueHelper: DeliveryFiltersDefaultValueHelper,
                sortOptions: {
                    attribute: 'deliveryDate',
                    sortType: 'date',
                    sortDirection: 'dsc'
                },
                itemsUrl: 'get_deliveries',
                itemUrl: 'get_delivery',
                requestType: 'POST',
                resourceName: 'deliveries',
                exportResourceName: 'deliveryExport',
                resourceDto: OrderManagementDTOService.getDeliveryDTO,
                resourceDetailsDto:
                    OrderManagementDTOService.getDeliveryDetailsDTO,
                clientSideFilters: ['deliveryStatus'],
                itemsResponseDataHandler: handleOrderManagementResponseData,
                modeView: 'list'
            }
        },
        order_dashboard: {
            namespaced: true,
            state: {
                items: [],
                item: {},
                itemsLoaded: false,
                filters: {},
                filterDefaultValueHelper: OrderFiltersDefaultValueHelper,
                sortOptions: {
                    attribute: 'orderDate',
                    sortType: 'date',
                    sortDirection: 'dsc'
                },
                itemsUrl: 'get_orders',
                itemUrl: 'get_order',
                requestType: 'POST',
                resourceName: 'orders',
                exportResourceName: 'orderExport',
                resourceDto: OrderManagementDTOService.getOrderDTO,
                resourceDetailsDto:
                    OrderManagementDTOService.getOrderDetailsDTO,
                clientSideFilters: ['orderStatus'],
                itemsResponseDataHandler: handleOrderManagementResponseData,
                modeView: 'list',
                hasRequestError: false,
            }
        },
        delivery_dashboard: {
            namespaced: true,
            state: {
                items: [],
                item: {},
                itemsLoaded: false,
                filters: {},
                filterDefaultValueHelper: DeliveryFiltersDefaultValueHelper,
                sortOptions: {
                    attribute: 'deliveryDate',
                    sortType: 'date',
                    sortDirection: 'dsc'
                },
                itemsUrl: 'get_deliveries',
                itemUrl: 'get_delivery',
                requestType: 'POST',
                resourceName: 'deliveries',
                exportResourceName: 'deliveryExport',
                resourceDto: OrderManagementDTOService.getDeliveryDTO,
                resourceDetailsDto:
                    OrderManagementDTOService.getDeliveryDetailsDTO,
                clientSideFilters: ['deliveryStatus'],
                itemsResponseDataHandler: handleOrderManagementResponseData,
                modeView: 'list',
                hasRequestError: false,
            }
        },
        coa: {
            namespaced: true,
            state: {
                items: [],
                filters: {},
                sortOptions: {
                    attribute: 'partnerNo',
                    sortType: 'numeric',
                    sortDirection: 'dsc'
                },
                itemsLoaded: false,
                itemsUrl: 'get_coaSearchList',
                requestType: 'POST',
                resourceName: 'coARowDataList',
                resourceDto: OrderManagementDTOService.getCoaRowData,
                itemsResponseDataHandler: handleOrderManagementResponseData,
                clientSideFilters: [],
                modeView: 'list'
            }
        },
        ship: {
            namespaced: true,
            state: {
                item: {},
                itemUrl: 'get_ship',
                requestType: 'POST',
                resourceName: 'ships',
                resourceDetailsDto:
                    OrderManagementDTOService.getShipDetailsDTO,
                modeView: 'list'
            }
        },
        accountSummaryInvoices: {
            namespaced: true,
            state: {
                items: [],
                item: {},
                itemsLoaded: false,
                accountSummaries: [],
                filters: {},
                filterDefaultValueHelper:
                    AccountSummaryFiltersDefaultValueHelper,
                sortOptions: {
                    attribute: 'invoiceIssueDate',
                    sortType: 'date',
                    sortDirection: 'dsc'
                },
                itemsUrl: 'get_invoices',
                itemUrl: 'get_invoices',
                requestType: 'POST',
                resourceName: 'invoices',
                resourceDto: InvoiceManagementDTOService.getInvoiceDTO,
                clientSideFilters: ['invoiceStatus'],
                itemsResponseDataHandler: handleGenericResponseData,
                modeView: 'list'
            }
        },
        invoices: {
            namespaced: true,
            state: {
                items: [],
                item: {},
                itemsLoaded: false,
                filters: {},
                filterDefaultValueHelper: InvoicesFiltersDefaultValueHelper,
                sortOptions: {
                    attribute: 'invoiceIssueDate',
                    sortType: 'date',
                    sortDirection: 'dsc'
                },
                itemsUrl: 'get_invoices',
                itemUrl: 'get_invoices',
                requestType: 'POST',
                resourceName: 'invoices',
                resourceDto: InvoiceManagementDTOService.getInvoiceDTO,
                clientSideFilters: ['invoiceStatus'],
                itemsResponseDataHandler: handleGenericResponseData,
                modeView: 'list'
            }
        },
        supportCenterCases: {
            namespaced: true,
            state: {
                items: [],
                item: {},
                itemsLoaded: false,
                filters: {},
                filterDefaultValueHelper: CaseFiltersDefaultValueHelper,
                sortOptions: {
                    attribute: 'dateSubmitted',
                    sortType: 'date',
                    sortDirection: 'dsc'
                },
                itemsUrl: 'get_cases',
                itemUrl: 'get_case_details',
                requestType: 'POST',
                resourceName: 'cases',
                resourceDto: SupportCenterDTOService.getCaseDTO,
                clientSideFilters: [],
                itemsResponseDataHandler: handleGenericResponseData,
                resourceDetailsDto: SupportCenterDTOService.getCaseDetailsDTO,
                modeView: 'list'
            }
        },
        myPartNumbers: Object.assign(MyPartNumbersModuleHelper, {
            state: Object.assign(MyPartNumbersModuleHelper.state, {
                itemsResponseDataHandler: handleGenericResponseData
            })
        }),
        favoriteBuyingOptions: Object.assign(
            FavoriteBuyingOptionsModuleHelper,
            {
                state: Object.assign(FavoriteBuyingOptionsModuleHelper.state, {
                    itemsResponseDataHandler: handleGenericResponseData
                })
            }
        ),
        previousPurchases: Object.assign(PreviousPurchasesModuleHelper, {
            state: Object.assign(PreviousPurchasesModuleHelper.state, {
                itemsResponseDataHandler: handleGenericResponseData
            })
        }),
        favoriteProducts: Object.assign(FavoriteProductsModuleHelper, {
            state: Object.assign(FavoriteProductsModuleHelper.state, {
                itemsResponseDataHandler: handleGenericResponseData
            })
        }),
        contractDetails: Object.assign(ContractDetailsModuleHelper, {
            state: Object.assign(ContractDetailsModuleHelper.state, {
                itemsResponseDataHandler: handleGenericResponseData
            })
        }),
        myInfo: {
            namespaced: true,
            state: {
                item: {},
                itemUrl: 'get_myInfo',
                requestType: 'POST',
                resourceName: 'myInfo',
                resourceDetailsDto: OrderManagementDTOService.getMyInfoWsDTO,
                modeView: 'list'
            }
        },
        myPreferences: {
            namespaced: true,
            state: {
                item: {},
                itemUrl: 'get_myPreferences',
                requestType: 'POST',
                resourceName: 'myPreferences',
                resourceDetailsDto: OrderManagementDTOService.getMyPreferencesWsDTO,
                modeView: 'list'
            }
        },
        customerAdmin: {
            namespaced: true,
            state: {
                searchOptions: INTERNAL_USER_SEARCH_OPTIONS,
                soldToShipToSearchOptions: SOLD_TO_SHIP_TO_SEARCH_OPTIONS,
                soldToSearchOptions: SOLD_TO_SEARCH_OPTIONS,
                searchResults: [],
                soldToSearchResults: [],
                shipToBySoldToSearchResults: [],
                externalAccessRoles: [],
                internalAccessRoles: [],
                communicationOptions: [],
                newExternalAdvancedUser: {}
            }
        }
    },
    methods: {
        isCustomDateRange: function (context, subModule) {
            const isCustomDateRange =
                context.state[subModule].filters['dateRange']?.isCustomDateRange
            return isCustomDateRange
        }
    },
    mutations: {
        setItems: function (state, payload) {
            const subModule = payload.subModule
            const items = payload.items
            state[subModule].items = sortItems(
                items,
                state[subModule].sortOptions
            )
            state[subModule].itemsLoaded = true
        },
        setItem: function (state, payload) {
            state[payload.subModule].item = payload.item
        },
        setAccountSummaries: function (state, payload) {
            const subModule = payload.subModule
            state[subModule].accountSummaries = payload.accountSummaries
        },
        setFilter: function (state, payload) {
            const subModule = payload.subModule
            const filter = payload.filter
            const filters = state[subModule].filters
            const currentAppliedValue =
                state[subModule].filters[filter.attribute]?.appliedValue

            const filterObj = {
                selectedValue: filter.value,
                isDefaultSelection: false,
                canApply: !filter.hasError,
                active: false,
                appliedValue: currentAppliedValue,
                isCustomDateRange: filter.isCustomDateRange
            }

            filters[filter.attribute] = filterObj
        },
        setActiveFilter: function (state, payload) {
            const subModule = payload.subModule
            const filterAttr = payload.filterAttr

            state[subModule].filters[filterAttr].active = true
        },
        setDefaultFilterValues: function (state, payload) {
            const subModule = payload.subModule
            const filterAttributes = payload.filterAttributes
            const filterDefaultValueHelper =
                state[subModule].filterDefaultValueHelper
            state[subModule].hasExportError = false

            const filterObj = filterAttributes.reduce((acc, cur) => {
                acc[cur] = {
                    selectedValue:
                        filterDefaultValueHelper.getDefaultValue(cur),
                    isDefaultSelection: true,
                    canApply: false,
                    active: false,
                    appliedValue: null,
                    isCustomDateRange: false
                }
                return acc
            }, {})
            state[subModule].filters = filterObj
        },
        resetFilters: function (state, payload) {
            Object.keys(state[payload.subModule].filters).forEach(
                (filterAttr) => {
                    resetFilterValue(state, payload.subModule, filterAttr)
                }
            )
        },
        resetFilterValue: function (state, payload) {
            resetFilterValue(state, payload.subModule, payload.filterAttr)
        },
        setAppliedValues: function (state, payload) {
            const subModule = payload.subModule
            const appliedFilters = payload.appliedFilters

            Object.keys(appliedFilters).forEach((attr) => {
                const value = appliedFilters[attr]
                state[subModule].filters[attr].appliedValue = value
            })
        },
        setSort: function (state, payload) {
            const subModule = payload.subModule
            const sort = payload.sort
            state[subModule].sortOptions = sort
            state[subModule].items = sortItems(
                state[subModule].items,
                state[subModule].sortOptions
            )
        },
        setModeView: function (state, payload) {
            state[payload.subModule].modeView = payload.modeView
        },
        setCanApply: function (state, payload) {
            const subModule = payload.subModule
            const filterAttr = payload.filterAttr
            const canApply = payload.canApply
            state[subModule].filters[filterAttr].canApply = canApply
        },
        setUserSearchResults: function(state, payload) {
            state[payload.subModule].searchResults = payload.responseData.customers;
        },
        setSoldToSearchResults: function(state, payload) {
            state[payload.subModule].soldToSearchResults = payload.responseData.b2bUnits;
        },
        setShipToBySoldToSearchResults: function(state, payload) {
            state[payload.subModule].shipToBySoldToSearchResults = payload.responseData.b2bUnitList;
        },
        setExternalAccessRoles: function(state, payload) {
            state[payload.subModule].externalAccessRoles = payload.responseData.userGroups;
        },
        setInternalAccessRoles: function(state, payload) {
            state[payload.subModule].internalAccessRoles = payload.responseData.userGroups;
        },
        setCommunicationOptions: function(state, payload) {
            state[payload.subModule].communicationOptions = payload.responseData.options;
        },
        setNewExternalAdvancedUser: function (state, payload) {
            state['customerAdmin'].newExternalAdvancedUser = payload;
        },
    },
    getters: {
        isCustomDateRange: function (state) {
            return function (subModule) {
                const isCustomDateRange =
                    state[subModule].filters['dateRange']?.isCustomDateRange
                return isCustomDateRange
            }
        },
        itemCount: function (state) {
            return function (subModule) {
                return state[subModule].items.length
            }
        },
        itemhasRequestError: function(state){
            return function (subModule) {
                return state[subModule].hasRequestError
            }
        },
        accountSummaries: function (state) {
            return function (subModule) {
                return state[subModule].accountSummaries
            }
        },
        showActiveFilterState: function (state) {
            return function (subModule, attr) {
                const filter = state[subModule].filters[attr]
                return (
                    filter.active &&
                    !filter.isDefaultSelection &&
                    !isEmptyStoreValue(filter.appliedValue)
                )
            }
        },
        isFilterActive: function (state) {
            return function (subModule, attr) {
                const filter = state[subModule].filters[attr]
                return filter.active && !filter.isDefaultSelection
            }
        },
        canFilterApply: function (state) {
            return function (subModule, attr) {
                return state[subModule].filters[attr].canApply
            }
        },
        selectedValue: function (state) {
            return function (subModule, attr) {
                return state[subModule].filters[attr]?.selectedValue
            }
        },
        activeServerFilters: function (state) {
            return function (subModule) {
                return getActiveFiltersByType(
                    state,
                    subModule,
                    isServerSideFilter
                )
            }
        },
        activeClientFilters: function (state) {
            return function (subModule) {
                return getActiveFiltersByType(
                    state,
                    subModule,
                    isClientSideFilter
                )
            }
        },
        activeFilters: function (state) {
            return function (subModule) {
                return getActiveFiltersByType(state, subModule)
            }
        },
        appliedValue: function (state) {
            return function (subModule, attr) {
                return state[subModule].filters[attr]?.appliedValue || null // TODO: temporary fix
            }
        },
        modeView: function (state) {
            return function (subModule) {
                return state[subModule]?.modeView
            }
        },
        filtersInitialized: function (state) {
            return function (subModule) {
                return !isEmptyStoreValue(state[subModule].filters)
            }
        },
        searchOptions: function(state) {
            return function (subModule) {
                return state[subModule].searchOptions;
            }
        },
        soldToShipToSearchOptions: function(state) {
            return function (subModule) {
                return state[subModule].soldToShipToSearchOptions;
            }
        },
        soldToSearchOptions: function(state) {
            return function (subModule) {
                return state[subModule].soldToSearchOptions;
            }
        },
        searchResults: function(state) {
            return function (subModule) {
                return state[subModule].searchResults;
            }
        },
        soldToSearchResults: function(state) {
            return function (subModule) {
                return state[subModule].soldToSearchResults;
            }
        },
        shipToBySoldToSearchResults: function(state) {
            return function (subModule) {
                return state[subModule].shipToBySoldToSearchResults;
            }
        },
        externalAccessRoles: function(state) {
            return function(subModule) {
                return state[subModule].externalAccessRoles;
            }
        },
        internalAccessRoles: function(state) {
            return function(subModule) {
                return state[subModule].internalAccessRoles;
            }
        },
        communicationOptions: function(state) {
            return function(subModule) {
                return state[subModule].communicationOptions;
            }
        },
        newExternalAdvancedUser: function(state) {
            return function(subModule) {
                return state[subModule].newExternalAdvancedUser;
            }
        }

    },
    actions: {
        getFilteredItems: function (context, subModule) {
            getFilteredItems(context, subModule)
        },
        getItem: function (context, payload) {
            getItem(context, payload.subModule, payload.params)
        },
        approveContract: function (context, payload) {
            approveContract(context, payload.subModule, payload.request)
        },
        performClientFiltersAndSet: function(context, subModule) {
            performClientFiltersAndSet(context, subModule, context.state[subModule].items, true)
        },
        reorder: function (context, payload) {
            return reorder(context, payload)
        },
        updatePassword: function (context, payload) {
            return updatePassword(context, payload)
        },
        setLoading: function(context, payload) {
            return setLoading(context, payload)
        },
        clearSearchUsers: function(context, subModule) {
            return context.state[subModule].searchResults = [];
        },
        clearSearchSoldTos: function(context, subModule) {
            return context.state[subModule].soldToSearchResults = [];
        },
        clearshipToBySoldTo: function(context, subModule) {
            return context.state[subModule].shipToBySoldToSearchResults = [];
        },
        searchUsers,
        searchSoldTos,
        searchShipTosBySoldTo,
        getSoldToDeliveryInfo,
        enableUser,
        getAccessRoles,
        getCommunicationOptions,
        removeUser,
        clearNewExternalAdvancedUser: function(context, subModule) {
            return context.state[subModule].newExternalAdvancedUser = {};
        },
    }
}

function sortItems(items, sortOptions) {
    return SortHelper.sort(items, sortOptions)
}

async function reorder(context, payload) {
    var url = context.rootState.settings.get_reOrder
    var params = payload.params
    var headers = { 'Cache-Control': 'no-cache' }
    var data = await ServletHelper.makeServletCall(url, params, headers)
    if (data !== null && data.totalUnitCount > 0) {
        var currentCount =
            context.rootState.user.dccUserInfo.data.numberOfCartItems
        var updatedTotalUnitCount = data.totalUnitCount - currentCount
        context.rootState.user.dccUserInfo.data.numberOfCartItems =
            data.totalUnitCount
        var postParams = new FormData()
        postParams.append('number', updatedTotalUnitCount)
        var postUrl = context.rootState.settings.post_purchase_count
        var postHeaders = {
            'Content-Type': 'multipart/form-data'
        }
        await ServletHelper.makePostServletCall(
            postUrl,
            postParams,
            postHeaders
        )
        payload.showModal = false
        payload.totalUnitCount = data.totalUnitCount
        payload.materialList = data.materialList
        sessionStorage.setItem('cartGuid', data.cartGuid)
        return payload
    } else {
        context.commit('loading/setLoading', false, { root: true })
        return (payload.showModal = true)
    }
}

function getFilteredItems(context, subModule) {
    var accountSummariesPromise;
    context.commit('loading/setLoading', true, { root: true })
    if (subModule == 'accountSummaryInvoices') {
        accountSummariesPromise = getAccountSummaries(context, subModule)
    }
    else{
        accountSummariesPromise = Promise.resolve();
    }
    const resource = context.state[subModule].resourceName
    const filterJson =
        subModule === 'delivery' || subModule ===  'delivery_dashboard'
            ? getDeliveryFilterRequestJson(context, subModule)
            : getFilterRequestJson(context, subModule)
    const resourcePath = context.state[subModule].itemsUrl
    const url = context.rootState.settings[resourcePath]
    const requestType = context.state[subModule].requestType
    var formData = new FormData()
    formData.append('filters', filterJson)
    
    if (["delivery_dashboard","order_dashboard"].includes(subModule)){
        context.state[subModule].hasRequestError=false
        context.commit('loading/setLoading', false, { root: true })
    }
    if (["contractDetails"].includes(subModule)){
        context.state[subModule].hasRequestError=false
    }

    ApiClient.request({
        method: requestType,
        url: url,
        data: requestType === 'POST' ? formData : new FormData(),
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then((res) => {
            let data = res.data
            data[resource] = data[resource] || []
            var generalPromise = handleFilteredItemsResponseData(context, subModule, data)
            Promise.all([accountSummariesPromise, generalPromise]).then(context.commit('loading/setLoading', false, { root: true }));
            context.state[subModule].isFromCache = res.data?.isFromCache || false
            if(
                [
                    "previouslyPurchasedProducts",
                    "favoriteBuyingOptions",
                    "cmir",
                    "orders",
                    "deliveries",
                    "contractDetails",
                    "invoices",
                    "coARowDataList",
                    "cases"
                ].includes(resource)) 
            {
                context.state[subModule].hasError = false
            }
        })
        .catch(function (error) {
            console.log(`Failed to load ${resource} -> ` + error)
            context.commit('loading/setLoading', false, { root: true })
            if(
                [
                    "previouslyPurchasedProducts",
                    "favoriteBuyingOptions",
                    "cmir",
                    "orders",
                    "deliveries",
                    "contractDetails",
                    "invoices",
                    "coARowDataList",
                    "cases"
                ].includes(resource)) 
            {
                context.state[subModule].hasError = true
                const resourceName = context.state[subModule].resourceName;
                const exportResourceName = context.state[subModule].exportResourceName;
                var noData = {}
                noData[resourceName] = []
                noData[exportResourceName] = []
                var generalPromise = handleFilteredItemsResponseData(context, subModule, noData)
                Promise.all([accountSummariesPromise, generalPromise]).then(context.commit('loading/setLoading', false, { root: true }));
            }

            if (["delivery_dashboard","order_dashboard","contractDetails"].includes(subModule)){
                context.state[subModule].hasRequestError=true
            }
        })

    
}

function getItem(context, subModule, params) {
    const resource = context.state[subModule].resourceName
    const resourceDetailsDto = context.state[subModule].resourceDetailsDto
    const url = context.rootState.settings[context.state[subModule].itemUrl]
    var errorDetailsDto = ""
    switch (subModule) {
        case 'order':
            errorDetailsDto = OrderManagementDTOService.getErrorOrderDetailsDTO(params.itemId)
            break;
        case 'ship':
            errorDetailsDto = OrderManagementDTOService.getErrorShipDetailsDTO()
            break;
        case 'myInfo':
            errorDetailsDto = OrderManagementDTOService.getErrorMyInfoWsDTO()
            break;
        case 'myPreferences':
            errorDetailsDto = OrderManagementDTOService.getErrorMyPreferencesWsDTO()
            break;
        default:
            errorDetailsDto = OrderManagementDTOService.getErrorDeliveryDetailsDTO(params.itemId)
        }

    context.commit('loading/setLoading', true, { root: true })

    ApiClient.get(url, { params, headers: { 'Cache-Control': 'no-cache' } })
        .then(function (res) {
            let data = res.data
            if (data === '["NULL Deliveries Returned"]' || data === '["NULL Orders Returned"]' 
            || data === '["NULL Ships Returned"]' || data === '["NULL MyInfo Returned"]'
            || data === '["NULL MyPreferences Returned"]') {
                context.commit('setItem', { subModule, item: errorDetailsDto })
            } else {
                context.commit('setItem', {
                    subModule,
                    item: resourceDetailsDto(data)
                })
            }
            context.commit('loading/setLoading', false, { root: true })
        })
        .catch(function (error) {
            console.log(`Failed to load ${resource} -> ` + error)
            context.commit('setItem', { subModule, item: errorDetailsDto })
            context.commit('loading/setLoading', false, { root: true })
        })
}

function approveContract(context, subModule, request) {
    const orderProcessingText = i18nHelper.getI18nMessage('order.processing')
    let items = context.state[subModule].items
    // update the status/action text of clicked row
    const updatedItems = items.map((item) => {
        if (item.contractNumber === request.contractNumber) {
            // Modify the text property of the matching element
            return {
                ...item,
                isOrderProcessing: true,
                contractStatus: 'order.processing',
                contractActionText: orderProcessingText
            }
        }
        return item
    })

    context.commit('setItems', { subModule, items: updatedItems })

    //trigger order create call
    const url = context.rootState.settings.create_order_from_contract
    const payload = JSON.stringify(request.contractNumber)
    const newPayload = payload.replace(/\"|\\/g, "")

    let formData = new FormData()
    formData.append('filters', newPayload)
    ApiClient.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then(function (res) {
            const statusCode = res.status
            if (statusCode === 200) {
                console.log('Order Create call was successful')
            } else {
                console.log('Order Create call was not successful')
            }
        })
        .catch(function (error) {
            console.error('Error in Order Create Call', error)
            this.isProcessing = false
        })
}

function filterItemsByClientSideFilters(items, activeFilters, subModule) {
    let retArr = [...items]

    Object.keys(activeFilters).forEach((attr) => {
        const filterValues = activeFilters[attr]
        if (attr == 'searchOption') {
            if (filterValues.value != '') {
                if (subModule == 'previousPurchases') {
                    retArr =
                        PreviousPurchasesFiltersDefaultValueHelper.filterSearchOption(
                            retArr,
                            filterValues
                        )
                }
                if (subModule == 'favoriteBuyingOptions') {
                    retArr =
                        FavoriteBuyingOptionsFiltersDefaultValueHelper.filterSearchOption(
                            retArr,
                            filterValues
                        )
                }

                if (subModule == 'myPartNumbers') {
                    retArr =
                        MyPartNumbersFiltersDefaultValueHelper.filterSearchOption(
                            retArr,
                            filterValues
                        )
                }
            }
        } else {
            retArr = retArr.filter((el) => filterValues.includes(el[attr]))
        }
    })

    return retArr
}

/**
 * This function generates a delivery filter JSON request object
 * It modifies the request object's 'toDate' with the configured future 'toDate' if the date-range selection is default [30,60,90] days
 */
function getDeliveryFilterRequestJson(context, subModule) {
    const deliveryFilterJsonStr = getFilterRequestJson(context, subModule)
    const isCustomDateRange =
        context.state[subModule].filters['dateRange']?.isCustomDateRange
    //for custom date selection, pass request as-is
    if (isCustomDateRange) {
        return deliveryFilterJsonStr
    }
    //get configured delivery toDate
    const configuredDefaultDeliveryToDate =
        context.state[
            subModule
        ].filterDefaultValueHelper.getDefaultDeliveryToDate()
    let deliveryFilterJson = JSON.parse(deliveryFilterJsonStr)
    //override with configured default deliveryToDate
    deliveryFilterJson.toDate = configuredDefaultDeliveryToDate
    return JSON.stringify(deliveryFilterJson)
}

function getFilterRequestJson(context, subModule) {
    const serverFiltersObject = context.getters.activeServerFilters(subModule)
    setAppliedFilterValues(context, subModule, serverFiltersObject)

    removeEmptyFilterValues(serverFiltersObject)
    serverFiltersObject.fromSubModule = subModule
    const serverFiltersForRequest =
        OrderManagementDTOService.getOrderManagementFilterDTO(
            serverFiltersObject
        )
    return JSON.stringify(serverFiltersForRequest)
    //return serverFiltersForRequest
}

function setAppliedFilterValues(context, subModule, filterObject) {
    context.commit('setAppliedValues', {
        subModule,
        appliedFilters: filterObject
    })
}

function handleFilteredItemsResponseData(context, subModule, data) {
    const itemsResponseDataHandler =
        context.state[subModule].itemsResponseDataHandler
    itemsResponseDataHandler(context, subModule, data)
    return Promise.resolve();
}

function performClientFiltersAndSet(context, subModule, items, clientSide) {
    const clientFiltersObject = context.getters.activeClientFilters(subModule)
    setAppliedFilterValues(context, subModule, clientFiltersObject)

    const resourceDto = context.state[subModule].resourceDto
    removeEmptyFilterValues(clientFiltersObject)
   
    if([
        'previousPurchases',
        'favoriteBuyingOptions',
        'myPartNumbers',
    ].includes(subModule)){
        performClientSideFiltering(context, subModule, items, resourceDto,clientFiltersObject,clientSide)
    }
    else {
    const clientFilteredItems = filterItemsByClientSideFilters(
        items,
        clientFiltersObject,
        subModule
    )
    const mappedItems = clientFilteredItems.map((o) => resourceDto(o))
    context.commit('setItems', { subModule, items: mappedItems })
    }
    
}

function performClientSideFiltering (context,subModule,items, resourceDto, clientFiltersObject, clientSide) {
    !clientSide ?
        context.state[subModule].unfilteredResults = items.map((o) => resourceDto(o))
    :      items = context.state[subModule].unfilteredResults

    
    const clientFilteredItems = filterItemsByClientSideFilters(
        items,
        clientFiltersObject,
        subModule
    )

    if(clientSide) {
        context.commit('setItems', { subModule, items: clientFilteredItems })
    }
    else {
        const mappedItems = clientFilteredItems.map((o) => resourceDto(o))
       context.commit('setItems', { subModule, items: mappedItems })
    
    }

}

function handleGenericResponseData(context, subModule, data) {
    const resource = context.state[subModule].resourceName
    performClientFiltersAndSet(context, subModule, data[resource], false)
}

function handleOrderManagementResponseData(context, subModule, data) {
    const resource = context.state[subModule].resourceName
    const exportResource = context.state[subModule].exportResourceName

    performClientFiltersAndSet(context, subModule, data[resource], false)
    if (!["delivery_dashboard","order_dashboard"].includes(subModule)){
        setExportOptions(context, subModule, data[exportResource] || [])
    }
    
}

function setExportOptions(context, subModule, exportOptions) {
    context.commit(
        'orderManagementExportOptions/setHasExportError',
        { subModule, value: false },
        { root: true }
    )
    context.commit(
        'orderManagementExportOptions/setExportOptions',
        { subModule, exportOptions },
        { root: true }
    )
}

function getActiveFiltersByType(
    state,
    subModule,
    filterTypeConditionCallback = () => true
) {
    const filters = state[subModule].filters
    const clientSideFilters = state[subModule].clientSideFilters
    return Object.keys(filters).reduce((acc, cur) => {
        if (
            filterTypeConditionCallback(cur, clientSideFilters) &&
            (filters[cur].active || filters[cur].isDefaultSelection)
        ) {
            acc[cur] = JSON.parse(JSON.stringify(filters[cur].selectedValue))
        }
        return acc
    }, {})
}

function resetFilterValue(state, subModule, filterAttr) {
    const filterDefaultValueHelper = state[subModule].filterDefaultValueHelper
    const filter = state[subModule].filters[filterAttr]

    filter.selectedValue = filterDefaultValueHelper.getDefaultValue(filterAttr)
    filter.isDefaultSelection = true
    filter.active = false
}

function isClientSideFilter(filterAttribute, clientFilterAttributes) {
    return clientFilterAttributes.includes(filterAttribute)
}

function isServerSideFilter(filterAttribute, clientFilterAttributes) {
    return !clientFilterAttributes.includes(filterAttribute)
}

function isEmptyStoreValue(value) {
    const normalizedValue = normalizeValue(value) // Convert Vuex Observer to JSON
    return (
        !normalizedValue ||
        JSON.stringify(normalizedValue) === '{}' ||
        JSON.stringify(normalizedValue) === '[]'
    )
}

function removeEmptyFilterValues(filterObject) {
    Object.keys(filterObject).forEach((key) => {
        const value = filterObject[key]

        if (isEmptyStoreValue(value)) {
            delete filterObject[key]
        }
    })
}

function normalizeValue(value) {
    return JSON.parse(JSON.stringify(value))
}

function getAccountSummaries(context, subModule) {
    const resource = 'accountSummaries'
    const filterJson = getFilterRequestJson(context, subModule)
    const url = context.rootState.settings.get_account_summary
    var formData = new FormData()
    formData.append('filters', filterJson)

    ApiClient.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then((res) => {
            let data = res.data
            const summaries = data[resource] || []
            const accountSummaries = summaries.map((o) =>
                InvoiceManagementDTOService.getAccountSummaryDTO(o)
            )
            context.commit('setAccountSummaries', {
                subModule,
                accountSummaries
            })
            return Promise.resolve();
        })
        .catch(function (error) {
            console.log(`Failed to load ${resource} -> ` + error)
            return Promise.resolve();
        })

        
}

function searchUsers(context, payload) {
    ApiClient.get(context.rootState.settings.user_search_servlet, {
        params: { searchAttribute: payload.attribute, searchValue: payload.value }
    })
    .then(function (response) {
        if(response.data.customers) {
            response.data.customers.forEach((searchResult) => {
                searchResult.categoriesVisible = false;
                searchResult.userDetailsVisible = false;
                searchResult.soldTosVisible = false;
            });
        }

        context.commit('setUserSearchResults', {'subModule': payload.subModule, 'responseData': response.data});

        if(payload.callback)
            payload.callback();
    })
    .catch(function (error) {
        console.log(error)
        if(payload.callback)
            payload.callback();
    })
}

function searchSoldTos(context, payload) {
    ApiClient.get(context.rootState.settings.user_sold_tos_servlet, {
        params: { searchCriteria: payload.attribute, searchString: payload.value, town: payload.town, salesOrg: '' }
    })
    .then(function (response) {
        context.commit('setSoldToSearchResults', {'subModule': payload.subModule, 'responseData': response.data});

        if(payload.callback)
            payload.callback();
    })
    .catch(function (error) {
        console.log(error)
        if(payload.callback)
            payload.callback();
    })
}

function searchShipTosBySoldTo(context, payload) {
    ApiClient.get(context.rootState.settings.user_delivery_addresses_for_selected_sold_to_servlet, {
        params: { sapCustomerNum: payload.sapCustomerNum, selectFiltered: true, userId: payload.userId, salesOrg: payload.salesOrg }
    })
    .then(function (response) {
        if(response.data.b2bUnitList) {
            response.data.b2bUnitList.forEach((searchResult) => {
                searchResult.isSelected = false;
            });
        }

        context.commit('setShipToBySoldToSearchResults', {'subModule': payload.subModule, 'responseData': response.data});

        if(payload.callback)
            payload.callback();
    })
    .catch(function (error) {
        console.log(error)
        if(payload.callback)
            payload.callback();
    })
}

function getAccessRoles(context, payload) {
    ApiClient.get(context.rootState.settings.user_access_roles_servlet, {
        params: { userType: payload.userType }
    })
    .then(function (response) {
        response.data.userGroups = response.data.userGroups.filter((i) => { return i.name != undefined });
        const contextAction = payload.userType === 'external' ? 'setExternalAccessRoles' : 'setInternalAccessRoles';
        context.commit(contextAction, {'subModule': payload.subModule, 'responseData': response.data});

        if(payload.callback)
            payload.callback();
    })
    .catch(function (error) {
        console.log(error)
        if(payload.callback)
            payload.callback();
    })
}

function getCommunicationOptions(context, payload) {
    ApiClient.get(context.rootState.settings.communication_options_servlet, {
        params: { isNewUser: payload.isNewUser }
    })
    .then(function (response) {
        response.data.options = response.data.options.sort((a, b) => a["key"] - b["key"]);
        response.data.options.unshift({ key: null, value: i18nHelper.getI18nMessage('please.select.Sentence') });

        response.data.options.forEach((option) => {
            if(option.languages) {
                option.languages.unshift(i18nHelper.getI18nMessage('please.select.Sentence'));
            }
        })

        context.commit('setCommunicationOptions', {'subModule': payload.subModule, 'responseData': response.data});

        if(payload.callback)
            payload.callback();
    })
    .catch(function (error) {
        console.log(error)
        if(payload.callback)
            payload.callback();
    })
}

function getSoldToDeliveryInfo(context, payload) {
    ApiClient.get(context.rootState.settings.user_delivery_address_servlet, {
        params: { uid: payload.uid }
    })
    .then(function (response) {
        const userSearchResult = context.state[payload.subModule].searchResults.filter((searchResult) => {
            return searchResult.displayUid === payload.uid;
        });

        userSearchResult.forEach((searchResult) => {
            if(response.data.soldToMapShipToForAdmin) {
                response.data.soldToMapShipToForAdmin.forEach((soldTo) => {
                    soldTo.deliveryAddressesVisible = false;
                });
            } else {
                searchResult.soldToMapShipToForAdmin = [];
            }

            Object.assign(searchResult, response.data);
        });
        if(payload.callback)
            payload.callback();
    })
    .catch(function (error) {
        console.log(error)
    })
}


function enableUser(context, payload) {
    return ApiClient.get(context.rootState.settings.customer_administration_servlet, {
        params: { userId: payload.userId }
    })
    .then(function (response) {
       return response;
    })
    .catch(function (error) {
        console.log(error);
        throw error;
    });
}

function removeUser(context, payload) {

    let userInfo = {
        userId: payload.user.uid,
        isDisabledUser: payload.isDisabledUser
    }
    return ApiClient.post(context.rootState.settings.remove_user_servlet, userInfo,{
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then(() => {
        var index = context.state[payload.subModule].searchResults.indexOf(payload.user);
        if(index > -1){
            context.state[payload.subModule].searchResults.splice(index, 1);
        }
    })
    .catch(function (error) {
        console.log(error);
        throw error;
    });
    
}
