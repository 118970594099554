import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "dc-form__group"
};
const _hoisted_2 = ["for"];
const _hoisted_3 = {
  class: "dc-form__control"
};
const _hoisted_4 = ["type", "id", "placeholder", "onChange"];
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  key: 1
};
const _hoisted_7 = {
  key: 0,
  class: "dc-form__help"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_VeeField = _resolveComponent("VeeField");
  const _component_ErrorMessage = _resolveComponent("ErrorMessage");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("label", {
    for: $options.randomId,
    class: _normalizeClass(["dc-form__label", {
      'tw-sr-only': $props.nolabel
    }])
  }, _toDisplayString($props.label), 11, _hoisted_2), _renderSlot(_ctx.$slots, "tooltip"), _createElementVNode("div", _hoisted_3, [_createVNode(_component_VeeField, _mergeProps({
    class: "dc-form__input",
    name: $props.name,
    label: $props.label,
    modelValue: $props.modelValue,
    id: $props.name,
    placeholder: $props.nolabel ? $props.placeholder || $props.label : $props.placeholder
  }, _ctx.$attrs, {
    rules: $props.rules,
    onInput: _cache[0] || (_cache[0] = $event => _ctx.$emit('modelUpdate', $event)),
    validateOnBlur: $props.validateOnBlur,
    validateOnChange: $props.validateOnChange,
    validateOnInput: $props.validateOnInput
  }), {
    default: _withCtx(({
      field,
      errorMessage
    }) => [_createElementVNode("input", _mergeProps({
      type: _ctx.$attrs.type,
      class: {
        invalid: !!errorMessage
      }
    }, field, {
      id: $options.randomId,
      placeholder: $props.placeholderInput,
      onChange: $event => $options.onChange(errorMessage)
    }), null, 16, _hoisted_4)]),
    _: 1
  }, 16, ["name", "label", "modelValue", "id", "placeholder", "rules", "validateOnBlur", "validateOnChange", "validateOnInput"]), _createVNode(_component_ErrorMessage, {
    name: $props.name,
    as: "p",
    class: "dc-form__error errormsg"
  }, {
    default: _withCtx(({
      message
    }) => [!$props.customErrorMessage ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(message), 1)) : _createCommentVNode("", true), $props.customErrorMessage ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($props.customErrorMessage), 1)) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["name"])]), $props.helpText ? (_openBlock(), _createElementBlock("p", _hoisted_7, "Optional Help text")) : _createCommentVNode("", true)]);
}