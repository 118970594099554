import i18nHelper from '../dow-platform/components/commerce/myAccount/helper/i18n-helper'
import store from '@/store'
import LanguageHelper from './language-helper'

export default class ContractDetailsDataHelper {
    static store = store

    static getStatusToolTip() {
        return (
            i18nHelper.getI18nMessage('contract.need.approval') +
            '\n' +
            i18nHelper.getI18nMessage('contract.available.to.view') +
            '\n' +
            i18nHelper.getI18nMessage('contract.status.processing') +
            '\n' +
            i18nHelper.getI18nMessage('contract.status.pending') +
            '\n' +
            i18nHelper.getI18nMessage('contract.status.expired')
        )
    }

    static getContractStatus(contractDetails, contractItem) {
        if (contractItem.itemStatus) {
            return contractItem.itemStatus.toLowerCase().replace(/\s+/g, '.')
        }
        if (!contractItem.itemStatus && contractItem.salesOrderNumber) {
            return 'order.created'
        }
        if (!contractItem.itemStatus && !contractItem.salesOrderNumber) {
            if (ContractDetailsDataHelper.isContractExpired(contractDetails)) {
                return 'contract.expired'
            }
            return 'pending.approval'
        }
        return ''
    }

    static getContractActionText(contractDetails, contractItem) {
        if (contractItem.itemStatus) {
            return i18nHelper.getI18nMessage('contract.customer.service')
        }
        if (!contractItem.itemStatus && contractItem.salesOrderNumber) {
            return `${i18nHelper.getI18nMessage(
                'order.detail.rightRail.vieworder'
            )}: ${contractItem.salesOrderNumber}`
        }

        if (!contractItem.itemStatus && !contractItem.salesOrderNumber) {
            if (ContractDetailsDataHelper.isContractExpired(contractDetails)) {
                return i18nHelper.getI18nMessage('contract.customer.service')
            }
            return i18nHelper.getI18nMessage('approve.pending.order')
        }
        return ''
    }

    static showContractItemInDashboard(contractItem) {
        if (contractItem.itemStatus) {
            return true
        }
        if (!contractItem.itemStatus && !contractItem.salesOrderNumber) {        
            return true           
        }
        return false
    }

    static getContractActionUrl(contractDetails, contractItem) {
        const languageCode = LanguageHelper.getLanguageCodeFromCookie()
        const supportUrl = `/${languageCode}/support.html`
        if (contractItem.itemStatus) {
            return supportUrl
        }
        if (!contractItem.itemStatus && contractItem.salesOrderNumber) {
            return ContractDetailsDataHelper.getOrderStatusUrl(contractItem)
        }
        if (!contractItem.itemStatus && !contractItem.salesOrderNumber) {
            if (ContractDetailsDataHelper.isContractExpired(contractDetails)) {
                return supportUrl
            }
        }
        return '#'
    }

    static getOrderStatusUrl(contractItem) {
        const language = LanguageHelper.getLanguageFromLanguageCodeCookie()
        return store.state.featureFlag.isNewOrderDetailsEnabled
            ? `#/my-orders/order-details/${contractItem.salesOrderNumber}`
            : `/dccstorefront/dcc/${language}/ordermanagement/orderDetail?orderNumber=${contractItem.salesOrderNumber}`
    }

    static isContractExpired(contractDetails) {
        const expiryDate = new Date(contractDetails.contractExpirationDate)
        const today = new Date()
        return expiryDate < today
    }

    static canApproveContract(contractDetails) {
        const contractItem = contractDetails.contractItems?.[0]
        // Convert expiryDate to a Date object
        const expiryDate = new Date(contractDetails.contractExpirationDate)
        const today = new Date()
        return (
            !contractItem.itemStatus &&
            !contractItem.salesOrderNumber &&
            expiryDate > today
        )
    }
}
